<template>
  <div class="return">
    <div class="topBack">{{$t('LAN.tips_title')}}</div>
    <div class='paying'>
        <img class="succImg" src="@/assets/common/paysucc.png" alt="">
        <p>{{$t('LAN.pay_return_tips')}}</p>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { getStore } from '@/utils/store'
import { useRoute } from 'vue-router'
import { CHANNEL } from '@/configs/global'
import { useStore } from 'vuex'

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute()
    const store = useStore()
      
    const state = reactive({
    });

    onMounted(() => {
        proxy.$I(proxy,getStore({ name: 'lang' }))

        var gameId = store.getters.gameId || 1;
        console.log("gameId:" + gameId);
        
        if (gameId != 1040) {
            try {
              var channel = route.query.channel || store.getters.channel || 1;
              var u = navigator.userAgent;
              var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
              var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
              if (
                parseInt(channel) == CHANNEL.H5 || 
                parseInt(channel) == CHANNEL.PC || 
                parseInt(channel) == CHANNEL.MICROSOFT || 
                parseInt(channel) == CHANNEL.ZY_IOS || 
                parseInt(channel) == CHANNEL.COPY_IOS || 
                parseInt(channel) == CHANNEL.COPY_IOS2) {
                  window.top.postMessage({
                      msg: "hzpayOrderNo",
                      outno: route.query.out_trade_no
                  }, "*");
              } else {
                  if (isAndroid || isiOS) {
                      window.prompt("zfOrderNo", route.query.out_trade_no);
                  }
              }
            } catch (error) {
                console.log(error)
            }
        }

    });

    return {
        ...toRefs(state),
    }
  },
  components: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.return {
  height: 100vh;

  .succImg {
        width: 40%;
        max-width: 220px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    
    p {
        font-size: 14px;
        color: #666;
        text-align: center;
        line-height: 1.4;
        margin-bottom: 5px;
    }
    
    a {
        color: #fff;
        background-color: #df6556;
        display: block;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
    }
    
    .topBack {
        background-color: #df6556;
        width: 100%;
        color: #fff;
        font-size: 16px;
        display: block;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        line-height: 50px;
    }
    
    .topBack img {
        width: 20px;
        margin-left: 20px;
    }
    
    .paying {
        display: flex;
        align-items: center;
        flex-flow: column;
        justify-content: center;
        height: 80%;
    }
}
</style>
